import Vue from 'vue'
import App from './App.vue'
import router from './router'
//引入store
import store from './store'
//域名文件
import  base_url  from './config/api'
Vue.prototype.$httpurl = base_url.base_url
Vue.prototype.$uploadUrl = base_url.base_url+'common/upload'
Vue.prototype.$uploadShibie= 'https://api.yjdjia.com/uploadimg'
// 引入富文本组件
import  VueQuillEditor from 'vue-quill-editor'
Vue.use(VueQuillEditor)
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

//引入elementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


//给图片路径添加路径
Vue.prototype.getimgsrc = function(url){
	let urlk = url;
	let str = RegExp('http');
	let newUrl;
	//通过三元运算符进行判断该图片是否含有http域名，没有就拼接上去https://www.zhaoyangjing.com
	str.test(urlk) ? newUrl = urlk : newUrl = base_url.img_url + urlk;
	const urls = newUrl
	return urls
}
//全局时间过滤
Vue.filter('dataFormat', function (value, showTime = false) {
  const date = new Date(value);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour12: false, // 显示24小时制
    timeZone: 'Asia/Shanghai', // 设置时区为中国标准时间
  };
  
  if (showTime) {
    options.hour = 'numeric';
    options.minute = 'numeric';
    options.second = 'numeric';
  }

  const timestampLength = value.toString().length;
  if (timestampLength === 10) {
    date.setTime(value * 1000);
  } else if (timestampLength === 13) {
    date.setTime(value);
  } else {
    return value; // 返回原始值
  }
  
  const formattedDate = date.toLocaleString('zh-CN', options);
  return formattedDate.replace(/\//g, '-');
})



import axios from 'axios'
// 请求的根路径
axios.defaults.baseURL = base_url.base_url
//请求拦截
axios.interceptors.request.use(config => {
  config.headers.token = store.state.token
  return config
})
// 响应拦截
axios.interceptors.response.use(
  response =>{
    if(response.data.code === 0){
      ElementUI.Message.warning(response.data.msg)
      return Promise.resolve(response)
    }else{
      return Promise.resolve(response)
    }
   
  },
  error =>{
    console.log('错误',error)
    if(error.response){
      switch(error.response.status){
        // 返回401，清除token信息并跳转到登录页面
        case 401:
        store._mutations.loginOut[0]()
        router.replace({
          path:'/login'
          //登录成功后跳入浏览的当前页面
          // query: {redirect: router.currentRoute.fullPath}
        })
      }
      // 返回接口返回的错误信息
      return Promise.reject(error.response.data);
      }
  }
)
Vue.prototype.$http = axios
Vue.prototype.$getkey = function(a,b){
  for (let key in b) {
      if(b[key]==a){
          return key
      }
  }
}

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  store
}).$mount('#app')
