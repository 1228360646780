<template>
    <div>
        <div class="BoxNav" v-if="group_id == 1 || group_id == 2">
            <el-row :gutter="20">
                <el-col :span="6" >
                    <div class="Boxcenter" @click="to_bianchengord">
                        <img src="../img/navimage/Code.png" alt="">
                        <p>发布编程订单</p>
                    </div>
                </el-col>
                <el-col :span="6" >
                    <div class="Boxcenter" @click="to_imgord">
                        <img src="../img/navimage/tuzhi.png" alt="">
                        <p>发布图纸订单</p>
                    </div>
                </el-col>
                <el-col :span="6" >
                    <div class="Boxcenter" @click="to_imgYibeijiedan">
                        <img src="../img/navimage/jishuanji.png" alt="">
                        <p>图纸订单已被接单</p>
                    </div>
                </el-col>
                <el-col :span="6" >
                    <div class="Boxcenter" @click="to_BianChengYibeijiedan">
                        <img src="../img/navimage/zongduan.png" alt="">
                        <p>编程订单已被接单</p>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="BoxNav" v-if="group_id == 3">
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="Boxcenter" @click="to_gongchengshicor_imgord">
                        <img src="../img/navimage/tuzhiguanli2.png" alt="">
                        <p>图纸订单接单记录</p>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="Boxcenter" @click="to_yijieshouBianCheng">
                        <img src="../img/navimage/tubiaomobanzhizuo.png" alt="">
                        <p>编程订单接单记录</p>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        data() {
            return {
                
            }
        },
        computed: {
            ...mapState(['group_id'])
        },
        mounted () {
            
        },
        methods: {
            //接受画图订单
            to_gongchengshicor_imgord(){
                this.$router.push("/gongchengshiadmin/imgord")
            },
            //已接收编程订单
            to_yijieshouBianCheng(){
                this.$router.push("/gongchengshiadmin/acceptprogrammingord")
            },
            //图纸已被接单
            to_imgYibeijiedan(){
                if(this.group_id == 1){
                    this.$router.push("/useradmin/tuzhiyibeijiedanlist")
                }else if(this.group_id == 2){
                    this.$router.push("/shopadmin/tuzhiyibeijiedanlist")
                }else if(this.group_id == 3){
                    this.$router.push("/gongchengshiadmin/tuzhiyibeijiedanlist")
                }
            },
            //跳转图纸订单发布
            to_imgord(){
                if(this.group_id == 1){
                    this.$router.push("/useradmin/useradmincomtuzhiord")
                }else if(this.group_id == 2){
                    this.$router.push("/shopadmin/useradmincomtuzhiord")
                }else if(this.group_id == 3){
                    this.$router.push("/gongchengshiadmin/useradmincomtuzhiord")

                }
            },
            //编程已被接单
            to_BianChengYibeijiedan(){
                if(this.group_id == 1){
                    this.$router.push("/useradmin/bianchengyibeijiedanlist")
                }else if(this.group_id == 2){
                    this.$router.push("/shopadmin/bianchengyibeijiedanlist")
                }else if(this.group_id == 3){
                    this.$router.push("/gongchengshiadmin/bianchengyibeijiedanlist")
                }
            },
             //跳转编程订单发布
             to_bianchengord(){
                if(this.group_id == 1){
                    this.$router.push("/useradmin/useradmincombianchenord")
                }else if(this.group_id == 2){
                    this.$router.push("/shopadmin/combianchenord")
                }else if(this.group_id == 3){
                    this.$router.push("/gongchengshiadmin/useradmincombianchenord")

                }
            },
        },
    }
</script>

<style lang="scss" scoped>
.BoxNav{
  width: 1160px;
  margin: auto;
  padding: 20px 20px;
  .Boxcenter{
    text-align: center;
    cursor: pointer;
    transition: all 0.5ms;
    img{
        width: 70px;
        height: 70px;
    }
    p{
        font-size: 16px;
        margin-top: 5px;
        font-weight: bold;
    }
  }
  .Boxcenter:hover{
    transition: all 0.5ms;
    transform: scale(1.1);
  }
}
</style>