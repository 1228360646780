import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store'

Vue.use(VueRouter)
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

const routes = [
  {
    path: '/',
    name: 'home',
    props: true,
    component: HomeView,
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/login',
    name: 'loginNanme',
    component: () => import('../views/login.vue'),
    meta: {
      hideHeader: true, //隐藏头: 真
      hidebottom:true,
    },
  },
  {
    path: '/register',
    name: 'registerNanme',
    component: () => import('../views/register.vue'),
    meta: {
      hideHeader: true, //隐藏头: 真
      hidebottom:true,
    },
  },
  {
    path: '/ordhome',
    name: 'ordhomeNanme',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ordHome.vue'),
    meta:{
      keepAlive:true,
      headerimg: true
    }
  },
  {
    path: '/shophot',
    name: 'shophotNanme',
    component: () => import('../views/shopHot.vue'),
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/shoplist',
    name: 'shoplistNanme',
    component: () => import('../views/shoplist.vue'),
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/shopjieshao',
    name: 'shopjieshaoNanme',
    component: () => import('../views/shopJieShao.vue')
  },
  {
    path: '/gongchengshihome',
    name: 'gongchengshihomeNanme',
    component: () => import('../views/gongchengshiHome.vue'),
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/gongchengshiinfo',
    name: 'gongchengshiinfoNanme',
    component: () => import('../views/gongchengshiInfo.vue')
  },
  {
    path: '/gongyingchanping',
    name: 'gongyingchanpingNanme',
    component: () => import('../views/gongyingChanPing.vue'),
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/hangyezhixun',
    name: 'hangyezhixunNanme',
    component: () => import('../views/hangyezhixun.vue'),
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/hangyeInfo',
    name: 'hangyeInfoNanme',
    component: () => import('../views/hangyeInfo.vue')
  },
  {
    path: '/zhisiquan',
    name: 'zhisiquanNanme',
    component: () => import('../views/zhisiQuan.vue'),
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/fabuzhishiquan',
    name: 'fabuzhishiquanNanme',
    component: () => import('../views/fabuZhiShiQuan.vue')
  },
  {
    path: '/zhishiquaninfo',
    name: 'zhishiquaninfoNanme',
    component: () => import('../views/zhishiquanInfo.vue')
  },
  {
    path: '/useradmin',
    name: 'useradminNanme',
    component: () => import('../views/userAdmin.vue'),
    meta: {
      headerimg: true
    },
    children: [
      {
        path: '/',
        redirect: 'useradminzhiliao'
      },
      {
        path:'MallOrderList',
        name:'MallOrderListUser',
        component: () => import('../views/MallPage/MallorderList/MallOrderList.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path:'MallOrderDetails',
        name:'MallOrderDetailsUser',
        component: () => import('../views/MallPage/MallOrderDetails/MallOrderDetails.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'chanpingordlook',
        name: 'chanpingordlookUser',
        component: () => import('../views/chanpingordLook.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminzhanneixin',
        name: 'useradminzhanneixinUser',
        component: () => import('../views/pagetow/useradmin_zhanneixin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'shiminrenzhen',
        name: 'shiminrenzhenNanme',
        component: () => import('../views/gongchengshiCore/shiminrenzhen.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'myordlist',
        name: 'myordlistUser',
        component: () => import('../views/shopCore/myordList.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'bianChenShiBie',
        name: 'bianChenShiBieNanme',
        component: () => import('../views/shibie/bianChenShiBie.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'imgShiBie',
        name: 'imgShiBieNanme',
        component: () => import('../views/shibie/imgShiBie.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminzhiliao',
        name: 'useradminzhiliaoNanme',
        component: () => import('../views/pagetow/useradmin_zhiliao.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminweixin',
        name: 'useradminweixinNanme',
        component: () => import('../views/pagetow/useradmin_weixin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminaddxunpan',
        name: 'useradminaddxunpanNanme',
        component: () => import('../views/pagetow/useradmin_addxunpan.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminallxunpan',
        name: 'useradminallxunpanNanme',
        component: () => import('../views/pagetow/useradmin_allxunpan.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminshouchanggongyingshang',
        name: 'useradminshouchanggongyingshangNanme',
        component: () => import('../views/pagetow/useradmin_shouchanggongyingshang.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminshouchangchanping',
        name: 'useradminshouchangchanpingNanme',
        component: () => import('../views/pagetow/useradmin_shouchangchanping.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminzhuijinggoumai',
        name: 'useradminzhuijinggoumaiNanme',
        component: () => import('../views/pagetow/useradmin_zhuijinggoumai.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminmyshopord',
        name: 'useradminmyshopordNanme',
        component: () => import('../views/pagetow/useradmin_myshopord.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminyouhuijuan',
        name: 'useradminyouhuijuanUser',
        component: () => import('../views/pagetow/useradmin_youhuijuan.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradmintuikantuihuo',
        name: 'useradmintuikantuihuoNanme',
        component: () => import('../views/pagetow/useradmin_tuikantuihuo.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradmindingyue',
        name: 'useradmindingyueNanme',
        component: () => import('../views/pagetow/useradmin_dingyue.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminshopcar',
        name: 'useradminshopcarNanme',
        component: () => import('../views/pagetow/useradmin_shopcar.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminzhanghuXX',
        name: 'useradminzhanghuXXNanme',
        component: () => import('../views/pagetow/useradmin_zhanghuXX.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminedpassword',
        name: 'useradminedpasswordNanme',
        component: () => import('../views/pagetow/useradmin_edpassword.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminshouhuodizhi',
        name: 'useradminshouhuodizhiNanme',
        component: () => import('../views/pagetow/useradmin_shouhuodizhi.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminmyintegral',
        name: 'useradminmyintegralNanme',
        component: () => import('../views/pagetow/useradmin_myIntegral.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradmintoushuAdmin',
        name: 'useradmintoushuAdminNanme',
        component: () => import('../views/pagetow/useradmin_toushuAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminjubaoAdmin',
        name: 'useradminjubaoAdminNanme',
        component: () => import('../views/pagetow/useradmin_jubaoAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradmincomtuzhiord',
        name: 'useradmincomtuzhiordNanme',
        component: () => import('../views/pagetow/useradmin_comTuZhiOrd.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradmintuzhiOrdAdmin',
        name: 'useradmintuzhiOrdAdminNanme',
        component: () => import('../views/pagetow/useradmin_tuzhiOrdAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      
      {
        path: 'useradmincombianchenord',
        name: 'useradmincombianchenordNanme',
        component: () => import('../views/pagetow/useradmin_comBianChenOrd.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminbianchenordadmin',
        name: 'useradminbianchenordadminNanme',
        component: () => import('../views/pagetow/useradmin_bianChenOrdAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'tuzhiyibeijiedanlist',
        name: 'tuzhiyibeijiedanlistUser',
        component: () => import('../views/pagetow/tuzhiyibeijiedanlist.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'bianchengyibeijiedan',
        name: 'bianchengyibeijiedanUser',
        component: () => import('../views/pagetow/bianchengyibeijiedan.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'bianchengyibeijiedanlist',
        name: 'bianchengyibeijiedanlistNanme',
        component: () => import('../views/pagetow/bianchengyibeijiedanlist.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'bianchengshouhou',
        name: 'bianchengshouhouNanme',
        component: () => import('../views/pagetow/bianchengshouhou.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'tuzhishouhou',
        name: 'tuzhishouhouUser',
        component: () => import('../views/pagetow/tuzhishouhou.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path:'myershouord',
        name:'myershouordUser',
        component: () => import('../views/pagetow/myershouOrd.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path:'myershouInfo',
        name:'myershouInfoUser',
        component: () => import('../views/pagetow/myershouInfo.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path:'ershouOrdAdmin',
        name:'ershouOrdAdminUser',
        component: () => import('../views/shopCore/ershouOrdAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path:'ershouordInfo',
        name:'ershouordInfoUser',
        component: () => import('../views/shopCore/ershouordInfo.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path:'vip',
        name:'vipUser',
        component: () => import('../views/vip.vue'),
        meta: {
          headerimg: true
        }
      }
    ]
  },
  {
    path: '/gongchengshiadmin',
    name: 'gongchengshiadminNanme',
    component: () => import('../views/gongchengshiAdmin.vue'),
    meta: {
      headerimg: true
    },
    children: [
      {
        path: '/',
        redirect: 'myordlist'
      },
      {
        path:'MallOrderList',
        name:'MallOrderListGongChengShi',
        component: () => import('../views/MallPage/MallorderList/MallOrderList.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'bianchengyibeijiedan',
        name: 'bianchengyibeijiedanGongChengShi',
        component: () => import('../views/pagetow/bianchengyibeijiedan.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path:'MallOrderDetails',
        name:'MallOrderDetailsGongChengShi',
        component: () => import('../views/MallPage/MallOrderDetails/MallOrderDetails.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminjubaoAdmin',
        name: 'useradminjubaoAdminGongChengShi',
        component: () => import('../views/pagetow/useradmin_jubaoAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradmindingyue',
        name: 'useradmindingyueGongChengShi',
        component: () => import('../views/pagetow/useradmin_dingyue.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'chanpingordlook',
        name: 'chanpingordlookNanme',
        component: () => import('../views/chanpingordLook.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminzhanneixin',
        name: 'useradminzhanneixinGongChengShi',
        component: () => import('../views/pagetow/useradmin_zhanneixin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminzhanneixin',
        name: 'useradminzhanneixinNanme',
        component: () => import('../views/pagetow/useradmin_zhanneixin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminyouhuijuan',
        name: 'useradminyouhuijuanNanme',
        component: () => import('../views/pagetow/useradmin_youhuijuan.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminweixin',
        name: 'useradminweixinGongChengShi',
        component: () => import('../views/pagetow/useradmin_weixin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'wenjianList',
        name: 'wenjianListNanme',
        component: () => import('../views/shibie/wenjianList.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'bianChenShiBie',
        name: 'bianChenShiBieGongChengShi',
        component: () => import('../views/shibie/bianChenShiBie.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'imgShiBie',
        name: 'imgShiBieGongChengShi',
        component: () => import('../views/shibie/imgShiBie.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'myinfo',
        name: 'myinfoNanme',
        component: () => import('../views/gongchengshiCore/myinfo.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradmincomtuzhiord',
        name: 'useradmincomtuzhiordGongChengShi',
        component: () => import('../views/pagetow/useradmin_comTuZhiOrd.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradmintuzhiOrdAdmin',
        name: 'useradmintuzhiOrdAdminGongChengShi',
        component: () => import('../views/pagetow/useradmin_tuzhiOrdAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradmincombianchenord',
        name: 'useradmincombianchenordGongChengShi',
        component: () => import('../views/pagetow/useradmin_comBianChenOrd.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminbianchenordadmin',
        name: 'useradminbianchenordadminGongChengShi',
        component: () => import('../views/pagetow/useradmin_bianChenOrdAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'shiminrenzhen',
        name: 'shiminrenzhenGongChengShi',
        component: () => import('../views/gongchengshiCore/shiminrenzhen.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'imgord',
        name: 'imgordNanme',
        component: () => import('../views/gongchengshiCore/imgOrd.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'imgordadmin',
        name: 'imgordadminNanme',
        component: () => import('../views/gongchengshiCore/imgOrdAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'acceptprogrammingord',
        name: 'acceptprogrammingordNanme',
        component: () => import('../views/gongchengshiCore/acceptProgrammingOrd.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'programmingadmin',
        name: 'programmingadminNanme',
        component: () => import('../views/gongchengshiCore/programmingAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'withdrawal',
        name: 'withdrawal',
        component: () => import('../views/gongchengshiCore/withdrawal.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'applicationrecord',
        name: 'applicationrecordNanme',
        component: () => import('../views/gongchengshiCore/applicationRecord.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'myordlist',
        name: 'myordlistGongChengShi',
        component: () => import('../views/shopCore/myordList.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminshouhuodizhi',
        name: 'useradminshouhuodizhiGongChengShi',
        component: () => import('../views/pagetow/useradmin_shouhuodizhi.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminedpassword',
        name: 'useradminedpasswordGongChengShi',
        component: () => import('../views/pagetow/useradmin_edpassword.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'jieshoutuzhiordInfo',
        name: 'jieshoutuzhiordInfoNanme',
        component: () => import('../views/gongchengshiCore/jieshoutuzhiordInfo.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'jieshoubianchengordInfo',
        name: 'jieshoubianchengordInfoNanme',
        component: () => import('../views/gongchengshiCore/jieshoubianchengordInfo.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'bianchengshouhou',
        name: 'bianchengshouhouGongChengShi',
        component: () => import('../views/pagetow/bianchengshouhou.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'tuzhishouhou',
        name: 'tuzhishouhouGongChengShi',
        component: () => import('../views/pagetow/tuzhishouhou.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'imgordshouhou',
        name: 'imgordshouhouNanme',
        component: () => import('../views/gongchengshiCore/imgordshouhou.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'acceptshouhou',
        name: 'acceptshouhouNanme',
        component: () => import('../views/gongchengshiCore/acceptshouhou.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path:'myershouord',
        name:'myershouordGongChengShi',
        component: () => import('../views/pagetow/myershouOrd.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path:'myershouInfo',
        name:'myershouInfo',
        component: () => import('../views/pagetow/myershouInfo.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path:'ershouOrdAdmin',
        name:'ershouOrdAdminGongChengShi',
        component: () => import('../views/shopCore/ershouOrdAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path:'ershouordInfo',
        name:'ershouordInfoGongChengShi',
        component: () => import('../views/shopCore/ershouordInfo.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path:'vip',
        name:'vip',
        component: () => import('../views/vip.vue'),
        meta: {
          headerimg: true
        }
      }

    ]
  },
  {
    path: '/ordinfo',
    name: 'ordinfoNanme',
    component: () => import('../views/ordInfo.vue'),
  },
  {//供应产品详情
    path: '/productdetails',
    name: 'productdetailsNanme',
    component: () => import('../views/productdetails.vue'),
  },
  {
    path: '/shopadmin',
    name: 'shopadminNanme',
    component: () => import('../views/shopAdmin.vue'),
    meta: {
      headerimg: true
    },
    children: [
      {
        path: '/',
        redirect: 'useradminzhiliao'
      },
      {
        path:'MallOrderList',
        name:'MallOrderListShop',
        component: () => import('../views/MallPage/MallorderList/MallOrderList.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path:'MallOrderDetails',
        name:'MallOrderDetailsShop',
        component: () => import('../views/MallPage/MallOrderDetails/MallOrderDetails.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminzhanneixin',
        name: 'useradminzhanneixinShop',
        component: () => import('../views/pagetow/useradmin_zhanneixin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminjubaoAdmin',
        name: 'useradminjubaoAdminShop',
        component: () => import('../views/pagetow/useradmin_jubaoAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradmindingyue',
        name: 'useradmindingyueshopAdmin',
        component: () => import('../views/pagetow/useradmin_dingyue.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminyouhuijuan',
        name: 'useradminyouhuijuanShop',
        component: () => import('../views/pagetow/useradmin_youhuijuan.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'wenjianList',
        name: 'wenjianListShop',
        component: () => import('../views/shibie/wenjianList.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'bianChenShiBie',
        name: 'bianChenShiBieShop',
        component: () => import('../views/shibie/bianChenShiBie.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'imgShiBie',
        name: 'imgShiBieShop',
        component: () => import('../views/shibie/imgShiBie.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminshouchanggongyingshang',
        name: 'useradminshouchanggongyingshangShop',
        component: () => import('../views/pagetow/useradmin_shouchanggongyingshang.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminshouchangchanping',
        name: 'useradminshouchangchanpingShop',
        component: () => import('../views/pagetow/useradmin_shouchangchanping.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminzhiliao',
        name: 'useradminzhiliaoNanmeShop',
        component: () => import('../views/pagetow/useradmin_zhiliao.vue'),
        meta: {
          headerimg: true
        }
      },
      // {
      //   path: 'myinfo',
      //   name: 'myinfo',
      //   component: () => import('../views/shopCore/myinfo.vue'),
      //   meta: {
      //     headerimg: true
      //   }
      // },
      {
        path: 'allxunjiadan',
        name: 'allxunjiadanNanme',
        component: () => import('../views/shopCore/allxunjiadan.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'addxunjiadan',
        name: 'addxunjiadanNanme',
        component: () => import('../views/shopCore/addxunjiadan.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'xunjiadan_Edit',
        name: 'xunjiadan_EditNanme',
        component: () => import('../views/shopCore/xunjiadan_Edit.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'company_qualification_list',
        name: 'company_qualification_listNanme',
        component: () => import('../views/shopCore/company_qualification_list.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'company_qualification',
        name: 'company_qualificationNanme',
        component: () => import('../views/shopCore/company_qualification.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'company_qualification_Edit',
        name: 'company_qualification_EditNanme',
        component: () => import('../views/shopCore/company_qualification_Edit.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'album_add',
        name: 'album_addNanme',
        component: () => import('../views/shopCore/album_add.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradmin_comTuZhiOrd_Edit',
        name: 'useradmin_comTuZhiOrd_EditNanme',
        component: () => import('../views/pagetow/useradmin_comTuZhiOrd_Edit.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'album',
        name: 'albumNanme',
        component: () => import('../views/shopCore/album.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'device',
        name: 'deviceNanme',
        component: () => import('../views/shopCore/device.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'device_Edit',
        name: 'device_EditNanme',
        component: () => import('../views/shopCore/device_Edit.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'weixin',
        name: 'weixinNanme',
        component: () => import('../views/pagetow/useradmin_weixin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'subaccount',
        name: 'subaccountNanme',
        component: () => import('../views/shopCore/subAccount.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminaddxunpan',
        name: 'useradminaddxunpanShop',
        component: () => import('../views/pagetow/useradmin_addxunpan.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminallxunpan',
        name: 'useradminallxunpanShop',
        component: () => import('../views/pagetow/useradmin_allxunpan.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'highord',
        name: 'highordNanme',
        component: () => import('../views/shopCore/highOrd.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'launchproducts',
        name: 'launchproductsNanme',
        component: () => import('../views/shopCore/launchProducts.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'launchproducts_Edit',
        name: 'launchproducts_EditNanme',
        component: () => import('../views/shopCore/launchProducts_Edit.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'productsadmin',
        name: 'productsadminNanme',
        component: () => import('../views/shopCore/productsAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'journalism',
        name: 'journalismNanme',
        component: () => import('../views/shopCore/journalism.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'journalismadmin',
        name: 'journalismadminNanme',
        component: () => import('../views/shopCore/journalismadmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'recruit',
        name: 'recruitNanme',
        component: () => import('../views/shopCore/recruit.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'recruitall',
        name: 'recruitallNanme',
        component: () => import('../views/shopCore/recruitall.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'combianchenord',
        name: 'combianchenordNanme',
        component: () => import('../views/pagetow/useradmin_comBianChenOrd.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'bianchenordadmin',
        name: 'bianchenordadminNanme',
        component: () => import('../views/pagetow/useradmin_bianChenOrdAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'acceptprogrammingord',
        name: 'acceptprogrammingordShop',
        component: () => import('../views/gongchengshiCore/acceptProgrammingOrd.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'programmingadmin',
        name: 'programmingadminShop',
        component: () => import('../views/gongchengshiCore/programmingAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradmincomtuzhiord',
        name: 'useradmincomtuzhiordShop',
        component: () => import('../views/pagetow/useradmin_comTuZhiOrd.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradmintuzhiOrdAdmin',
        name: 'useradmintuzhiOrdAdminShop',
        component: () => import('../views/pagetow/useradmin_tuzhiOrdAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'imgord',
        name: 'imgordShop',
        component: () => import('../views/gongchengshiCore/imgOrd.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'imgordadmin',
        name: 'imgordadminShop',
        component: () => import('../views/gongchengshiCore/imgOrdAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'productsordadmin',
        name: 'productsordadminNanme',
        component: () => import('../views/shopCore/productsOrdAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'applicationrecord',
        name: 'applicationrecordShop',
        component: () => import('../views/gongchengshiCore/applicationRecord.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'withdrawal',
        name: 'withdrawalNanme',
        component: () => import('../views/gongchengshiCore/withdrawal.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'secondhand',
        name: 'secondhandNanme',
        component: () => import('../views/shopCore/secondHand.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'secondHand_Edit',
        name: 'secondHand_EditNanme',
        component: () => import('../views/shopCore/secondHand_Edit.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'secondhandadmin',
        name: 'secondhandadminNanme',
        component: () => import('../views/shopCore/secondHandAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'enterprisecertification',
        name: 'enterprisecertificationNanme',
        component: () => import('../views/shopCore/enterpriseCertification.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'advertisementtop',
        name: 'advertisementtopNanme',
        component: () => import('../views/shopCore/advertisementTop.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'advertisementbottom',
        name: 'advertisementbottomNanme',
        component: () => import('../views/shopCore/advertisementbottom.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'advertisementleftright',
        name: 'advertisementleftrightNanme',
        component: () => import('../views/shopCore/advertisementleftright.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'useradminshouhuodizhi',
        name: 'useradminshouhuodizhiShop',
        component: () => import('../views/pagetow/useradmin_shouhuodizhi.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'myordlist',
        name: 'myordlistNanme',
        component: () => import('../views/shopCore/myordList.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'chanpingordlook',
        name: 'chanpingordlookShop',
        component: () => import('../views/chanpingordLook.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'shopchanpingord',
        name: 'shopchanpingordNanme',
        component: () => import('../views/shopCore/shopchanpingOrd.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'bianchengyibeijiedanlist',
        name: 'bianchengyibeijiedanlist',
        component: () => import('../views/pagetow/bianchengyibeijiedanlist.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'bianchengyibeijiedan',
        name: 'bianchengyibeijiedanNanme',
        component: () => import('../views/pagetow/bianchengyibeijiedan.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'tuzhiyibeijiedanlist',
        name: 'tuzhiyibeijiedanlistNanme',
        component: () => import('../views/pagetow/tuzhiyibeijiedanlist.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'tuzhiyibeijiedan',
        name: 'tuzhiyibeijiedanNanme',
        component: () => import('../views/pagetow/tuzhiyibeijiedan.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'comBianChenOrdedit',
        name: 'comBianChenOrdeditNanme',
        component: () => import('../views/pagetow/useradmin_comBianChenOrd_Edit.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'bianchengshouhou',
        name: 'bianchengshouhouShop',
        component: () => import('../views/pagetow/bianchengshouhou.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path: 'tuzhishouhou',
        name: 'tuzhishouhouNanme',
        component: () => import('../views/pagetow/tuzhishouhou.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path:'myershouord',
        name:'myershouordNanme',
        component: () => import('../views/pagetow/myershouOrd.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path:'myershouInfo',
        name:'myershouInfoNanme',
        component: () => import('../views/pagetow/myershouInfo.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path:'ershouOrdAdmin',
        name:'ershouOrdAdminNanme',
        component: () => import('../views/shopCore/ershouOrdAdmin.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path:'ershouordInfo',
        name:'ershouordInfoShop',
        component: () => import('../views/shopCore/ershouordInfo.vue'),
        meta: {
          headerimg: true
        }
      },
      {
        path:'vip',
        name:'vipNanme',
        component: () => import('../views/vip.vue'),
        meta: {
          headerimg: true
        }
      }
    ]
  },
  {
    path:'/chanpingord',
    name:'chanpingordNanme',
    component: () => import('../views/chanpingord.vue'),
    meta: {
      headerimg: true
    }
  },
  {
    path:'/zhifuover',
    name:'zhifuoverNanme',
    component: () => import('../views/shopCore/zhifuOver.vue'),
    meta: {
      headerimg: true,
      hidebottom:true
    }
  },
  {
    path:'/tuzhiinfo',
    name:'tuzhiinfoNanme',
    component: () => import('../views/pagetow/tuzhiInfo.vue'),
    meta: {
      headerimg: true
    }
  },
  {
    path:'/bianchenginfo',
    name:'bianchenginfoNanme',
    component: () => import('../views/pagetow/bianchengInfo.vue'),
    meta: {
      headerimg: true
    }
  },
  {
    path:'/ershouList',
    name:'ershouListNanme',
    component: () => import('../views/ershouList.vue'),
    // meta: {
    //   headerimg: true
    // }
  },
  {
    path:'/ershouInfo',
    name:'ershouInfoNanme',
    component: () => import('../views/ershouInfo.vue'),
    // meta: {
    //   headerimg: true
    // }
  },
  {
    path:'/ershouordInfo',
    name:'ershouordInfoNanme',
    component: () => import('../views/ershouordInfo.vue'),
    // meta: {
    //   headerimg: true
    // }
  },
  {
    path:'/ordclasslist',
    name:'ordclasslistNanme',
    component: () => import('../views/ordclasslist.vue'),
    meta: {
      headerimg: true,
      keepAlive:true
    }
  },
  {
    path:'/AboutUs',
    name:'AboutUsNanme',
    component: () => import('../views/platform/AboutUs.vue'),
    meta: {
      headerimg: true,
      hidebottom:true
    }
  },
  {
    path:'/TermsService',
    name:'TermsServiceNanme',
    component: () => import('../views/platform/TermsService.vue'),
    meta: {
      headerimg: true,
      hidebottom:true
    }
  },
  {
    path:'/law',
    name:'lawNanme',
    component: () => import('../views/platform/law.vue'),
    meta: {
      headerimg: true,
      hidebottom:true
    }
  },
  {
    path:'/business',
    name:'businessNanme',
    component: () => import('../views/platform/business.vue'),
    meta: {
      headerimg: true,
      hidebottom:true
    }
  },
  {
    path:'/agency',
    name:'agencyNanme',
    component: () => import('../views/platform/agency.vue'),
    meta: {
      headerimg: true,
      hidebottom:true
    }
  },
  {
    path:'/ContactUs',
    name:'ContactUsNanme',
    component: () => import('../views/platform/ContactUs.vue'),
    meta: {
      headerimg: true,
      hidebottom:true
    }
  },
  {
    path:'/HelpCenter',
    name:'HelpCenterNanme',
    component: () => import('../views/platform/HelpCenter.vue'),
    meta: {
      headerimg: true,
      hidebottom:true
    }
  },
  {
    path:'/mallPageHome',
    name:'mallPageHomeName',
    component: () => import('../views/MallPage/mallPageHome/mallPageHome.vue'),
    meta: {
      headerimg: true,
      hidebottom:true,
      keepAlive:true
    }
  },
  {
    path:'/ProductDetailsMall',
    name:'ProductDetailsName',
    component: () => import('../views/MallPage/ProductDetails/ProductDetails.vue'),
    meta: {
      headerimg: true,
      hidebottom:false
    }
  },
  {
    path:'/MallOrder',
    name:'MallOrderName',
    component: () => import('../views/MallPage/MallOrder/MallOrder.vue'),
    meta: {
      headerimg: true,
      hidebottom:false
    }
  },
  {
    path:'/copyright',
    name:'copyrightName',
    component: () => import('../views/platform/copyright.vue'),
    meta: {
      headerimg: true,
      hidebottom:true
    }
  }
  
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next)=>{
  // console.log('路由',to.matched[0].name)
  if(to.matched[0].name == 'gongchengshiadmin'){
    if(store.state.group_id == 3){
      next()
    }else{
      Vue.prototype.$message.error('暂无权限')
    }
  }else if(to.matched[0].name == 'shopadmin'){
    if(store.state.group_id == 2){
      next()
    }else{
      Vue.prototype.$message.error('暂无权限')
    }
  }else if(to.matched[0].name == 'useradmin'){
    if(store.state.group_id == 1){
      next()
    }else{
      Vue.prototype.$message.error('暂无权限')
    }
  }else{
    next()
  }
})

export default router
