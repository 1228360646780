<template>
  <div >
    <!-- 头部登录导航 -->
   <div class="top_wecoment">

    <div class="top_box">

      <div>
        <p class="title">欢迎来到海南新智造科技有限公司，专业外协加工信息服务平台</p>
      </div>
      <div>
        <ul class="ul_login">
          <li v-if="group_id" class="hover-pointer" @click="outlogin">退出登录</li>
          <!-- <li v-if="gid==1">我是采购</li>
          <li v-if="gid==2">我是厂家</li>
          <li v-if="gid==3">我是工程师</li> -->
          <li v-if="group_id">{{ user.nickname }}</li>
          
          <template v-if="!group_id">
            <li class="hover-pointer" @click="to_login">请登录</li>
            <li class="hover-pointer" @click="to_register">免费注册</li>
          </template>
          <!-- <li>河南省</li> -->
          <li class="hover-pointer" @click="to_useradmin">管理中心</li>
        </ul>
      </div>

    </div>
      
   </div>
   <!-- 搜索导航 -->
   <div class="inputnav">
    <div class="login">
      <img src="@/img/logo2.png" alt="" style="height: 50px;">
    </div>
    <!-- <div class="inp">
      <p><input type="text"><span style="border-left:1px solid rgb(177, 177, 177);padding: 8px;">搜索</span> </p>
    </div> -->
    <!-- <div class="inp fabu" style="padding: 10px 20px;">
      <p>发布询价单 </p>
    </div> -->
    <div class="tel">
      <div>
        <!-- <img src="@/img/zhaoyangjing.png" > -->
      </div>
      <div>
        <p style="font-size: 18px;">免费咨询:<span style="margin-left: 10px;">4000-129-997</span> </p>
        <!-- <p></p> -->
      </div>
     
    </div>
   </div>
   <!-- 导航分类 -->
   <div class="nav">
      <div class="box"  @mouseleave="onMouseOut">
          <li class="boli" @click="navlist(0)" style="width: 180px;background-color:#3f84df;">商品分类</li>
          <li class="boli" @click="to_homview(1)" :class="{col:headType == 1}">首页</li>
          <li class="boli" @click="to_ordhome(2)" :class="{col:headType == 2}">订单大厅</li>
          <li class="boli" @click="to_shophot(3)" :class="{col:headType == 3}">商家推荐</li>
          <li class="boli" @click="to_shoplist(4)" :class="{col:headType == 4}">商家工厂</li>
          <li class="boli" @click="to_ershoulist(5)" :class="{col:headType == 5}">二手产品</li>
          <li class="boli" @click="to_gongchengshihome(6)" :class="{col:headType == 6}">工程师大厅</li>
          <li class="boli" @click="to_gongyingChanPing(7)" :class="{col:headType == 7}">供应产品</li>
          <li class="boli" @click="to_hangyezhixun(8)" :class="{col:headType == 8}">行业资讯</li>
          <li class="boli" @click="to_zhisiquan(9)" :class="{col:headType == 9}">知识圈</li>
          <li class="boli" @click="to_Mall(10)" :class="{col:headType == 10}">商城</li>

           <!-- 二级分类 -->
           <!-- v-show="navshow" -->
          <ul class="classtow" v-show="!$route.meta.headerimg">
            <li class="name" style="cursor: pointer;" v-for="(item,index) in gongyi" :key="index" @mousemove="yiru(item)" ><span>{{ item.name }}</span></li>
          </ul>
          <div class="fenlei">
              <div class="fenleiclass" v-for="(item,index) in erjigongyin" :key="index">
                  <p class="title">{{ item.name }}</p>
                  <ul>
                    <li v-for="(item2,index2) in item.childlist" :key="index2" @click="to_ordclasslist(item2.id)" style="cursor: pointer;">{{ item2.name }}</li>
                  </ul>
              </div>
          </div>
      </div>
   </div>
   <!-- 导航图 -->
   <div class="bannerimg" v-show="!$route.meta.headerimg">
    <el-carousel height="500px" trigger="click">
      <el-carousel-item v-for="item in advertising[0].image" :key="item.id">
        <img :src="getimgsrc(item)" alt="">
      </el-carousel-item>
    </el-carousel>
    
   </div>
   

  </div>
</template>

<script>
import { mapState,mapMutations } from 'vuex'
export default {
  data () {
    return {
      colc:1,
      input:'',
      navshow:false,
      type: 1,
      banners:[],
      gongyi:[],
      erjigongyin:[],
    }
  },
  computed:{
    ...mapState(['group_id','user','advertising','headType'])
  },
  watch:{
    colc(newVal,oldVal){
      this.$store.commit('setHeaderType',newVal)
    }
  },
  mounted(){
    this.bannner()
    this.getgongyi()
  },
  methods: {
    ...mapMutations(['loginOut']),
    //工艺跳转询盘列表
    to_ordclasslist(id){
      let list = document.getElementsByClassName('fenlei')[0]
      list.style.display = "none"
      this.$router.push({
        path:'/ordclasslist',
        query:{
          id
        }
      })
    },
    //移入
    yiru(item){
      this.erjigongyin = item.childlist
      let list = document.getElementsByClassName('fenlei')[0]
      list.style.display = "block"
    },
    //移除
    onMouseOut(){
      let list = document.getElementsByClassName('fenlei')[0]
      list.style.display = "none"
    },
    //获取工艺
    getgongyi(){
      this.$http.post('v1/goods/goodsCat',{
        type:'gongyi'
      }).then(res =>{
        this.gongyi = res.data.data
      })
    },
    //退出登录
    outlogin(){
      this.loginOut()
      this.$message.success('退出成功')
      setTimeout(() => {
        this.to_login()
      }, 1200);
      
    },
    //跳转商城
    to_Mall(i){
      this.colc = i
      this.$router.push('/mallPageHome')
    },
    //跳转二手列表
    to_ershoulist(i){
      this.colc = i
      this.$router.push('/ershouList')
    },
     //跳转商家工厂
      to_shoplist(i){
        this.colc = i
        this.$router.push('/shoplist')
      },
      /**
       * 跳转到注册
      */
      to_register(i) {
        this.colc = i
        this.$router.push('/register')
      },

    // 获取轮播图
    // v1/web/index/adList
    async bannner(){
      const {data:res} = await this.$http.post('v1/web/index/adList',{
        adcat:1
      })
      this.banners = res.data
    },

    //管理中心
    async to_useradmin(){
      const {data:res} = await this.$http.post('v1/user/index')
      // console.log(res);
      this.$store.commit('setUser',res.data.userinfo)
      this.$store.commit('setgroup_id',res.data.userinfo.group_id)
      if(this.group_id == 1){
        this.$router.push("/useradmin")
      }else if(this.group_id == 2){
        this.$router.push("/shopadmin")
      }else if(this.group_id == 3){
        this.$router.push("/gongchengshiadmin")
      }else{
        this.$message.warning('请先登录！')
      }
      
    },
    //知识圈
    to_zhisiquan(i){
      this.colc = i
      this.$router.push("/zhisiquan")
    },
    //行业咨询
    to_hangyezhixun(i){
      this.colc = i
      this.$router.push("/hangyezhixun")
    },
    //供应产品
    to_gongyingChanPing(i){
      this.colc = i
      this.$router.push("/gongyingchanping")
    },
    //工程师大厅
    to_gongchengshihome(i){
      this.colc = i
      this.$router.push("/gongchengshihome")
    },
    //商家推荐
    to_shophot(i){
      this.colc = i
      this.$router.push("/shophot")
    },
    //首页
    to_homview(i){
      this.colc = i
      this.$router.push("/")
    },
    //订单大厅
    to_ordhome(i){
      this.colc = i
      this.$router.push("/ordhome")
    },
    navlist(i){
      this.colc = i
      // this.navshow = !this.navshow
    },
    to_login(){
      this.$router.push("/login")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.col{
  background-color: rgb(63, 132, 223);
    color: white;
}
.hover-pointer {
  cursor: pointer;
}
li{
  list-style: none;
}
.top_wecoment{
  width: 100%;
  margin: auto;
  background-color: rgb(230, 230, 230);
  padding: 5px 0px;
  .top_box{
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
      .title{
      color: rgb(88, 88, 88);
      font-size: 16px;
    }
    .ul_login{
      display: flex;
      li{
        color: rgb(88, 88, 88);
        font-size: 16px;
        margin-right: 10px;
      }
      li:hover{
        color: rgb(63, 132, 223);
      }
    }
  }
  
}
.inputnav{
  width: 1200px;
  margin:8px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .inp{
    border-radius: 20px;
    padding: 5px;
    border: rgb(177, 177, 177) 1px solid;
    span{
      padding: 3px;
      font-size: 14px;
      border-left: 1rpx solid rgb(88, 88, 88);
    }
    input{
      padding: 5px;
      border: none;
      outline:none
    }
    
  }
  .tel{
    display: flex;
    align-items: center;
    color: rgb(177, 177, 177);
  }
}
.fabu{
      font-size: 10px;
    }
.nav{
  background-color: #222222;
  // color: white;
  width: 100%;
  margin: auto;
  // padding: 5px;


  // position: -webkit-sticky; /* Safari */
  // position: sticky;
  // top: 0;


  // position: fixed;
  .box{
    position: relative;
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    .boli{
      cursor: pointer;
      width: 100px;
      padding: 7px 0px;
      text-align: center;
      color: white;
    }
    .boli:hover {
     background-color: rgb(63, 132, 223);
     color: white;
    }
    .classtow{
        width: 180px;
        background-color: rgba(54, 54, 54, 0.46);
        position: absolute;
        top: 35px;
        left: 0px;
        z-index: 999;
        .name{
          padding: 10.3px 0px;
          text-align: center;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          span{
            width: 105px;
          }
          img{
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }
        }
        .name:hover {
          transition: all 0.1s;
          background-color: rgb(63, 132, 223);
          color: white;
        }
      }
      .blockft{
        display: none;
      }
      
      .fenlei{
        display: none;
        width: 1000px;
        height: 490px;
        background-color: white;
        position: absolute;
        top: 40px;
        left: 180px;
        z-index: 999;
        .fenleiclass{
          display: flex;
          padding: 10px 0px;
          border-bottom: 1px solid #e9e9e9;
          .title{
            margin-left: 10px;
            color: #006FE1;
            font-size: 14px !important;
            height: 70px;
            width: 90px !important;
            font-weight: bold;
          }
          ul{
            margin-left: 10px;
            width: 90%;
            display: flex;
            flex-wrap: wrap;
            li{
              margin-right: 20px;
              margin-bottom: 10px;
              width: 90px;
              color: #333;
              font-size: 14px;
            }
            li:hover{
              color: #40bdf9;
            }
          }
        }
      }
  }
  
}
.bannerimg{
  height: 500px;
  img{
    width: 100%;
    height: 500px;
  }
}
</style>
