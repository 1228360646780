import Vue from 'vue'
import Vuex from 'vuex'
// 应用vuex插件
Vue.use(Vuex)
 
// 创建并暴露store
export default new Vuex.Store({
    // 数据,相当于data
    state: { 
        token:localStorage.getItem("ZHAOYANGJING_Token") || '',//用户token
        user: JSON.parse(localStorage.getItem("ZHAOYANGJING_User")) || {},//用户信息
        group_id:localStorage.getItem("ZHAOYANGJING_Group_id") || '',
        advertising:JSON.parse(localStorage.getItem("ZHAOYANGJING_advertising")) || {},//平台广告
        headType:sessionStorage.getItem("ZHAOYANGJING_headType") || 0,//平台广告
    },
    //准备getters——用于将state中的数据进行加工
    getters: {
 
    },
    //准备mutations——用于操作数据（state）
    mutations: { 
        //设置当前头部导航
        setHeaderType(state,headType){
            state.headType = headType
            window.sessionStorage.setItem("ZHAOYANGJING_headType", headType)
        },
        //设置广告信息
        setAdvertising(state,advertising){
            state.advertising = advertising
            window.localStorage.setItem("ZHAOYANGJING_advertising", JSON.stringify(advertising))
        },
         //设置用户token
        setToken(state,usertoken){
            state.token = usertoken
            window.localStorage.setItem("ZHAOYANGJING_Token", usertoken)
        },
         //用户信息
        setUser(state,user){
            state.user = user
            window.localStorage.setItem("ZHAOYANGJING_User", JSON.stringify(user))
        },
         //用户信息
        setgroup_id(state,group_id){
            state.group_id = group_id
            window.localStorage.setItem("ZHAOYANGJING_Group_id", group_id)
        },
        //退出登录
        loginOut(state,out){
            state.token = ''
            state.user = {}
            state.group_id = ''
            window.localStorage.removeItem('ZHAOYANGJING_Group_id')
            window.localStorage.removeItem('ZHAOYANGJING_Token')
            window.localStorage.removeItem('ZHAOYANGJING_User')
        },
    },
    //准备actions——用于响应组件中的动作
    actions: {
        
    },
    modules: {}
})