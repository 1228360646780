<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> 
      <router-link to="/about">About</router-link>
    </nav> -->
    <headernav v-show="!$route.meta.hideHeader"></headernav>
    <keep-alive >
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>
    <bottom v-show="!$route.meta.hidebottom"></bottom>
  </div>
</template>

<script>
import headernav from '@/components/headernav.vue'
import bottom from '@/components/bottom.vue'
export default {
    data () {
      return {

      }
    },
    components:{
      headernav,
      bottom
    },
}
</script>

<style lang="scss">
*{
  padding: 0px;
  margin: 0px;
}
</style>
